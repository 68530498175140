.navbarLogout {
  color: var(--gray-color);
  font-size: var(--font-size-secondary);
  font-family: var(--font-secondary);
  font-weight: 700;
}

.Navbar {
  display: flex;
  gap: 15px;
  align-items: center;
  font-weight: 600;
}

.activeNavbarItem {
  background-color: var(--fourth-color);
  border-radius: var(--border-radius-size-primary);
}

.navbarItem {
  padding: 8px 8px;
  line-height: 1;
}

.Button {
  cursor: pointer;
  color: inherit;

  &:hover {
    color: var(--secondary-color);
  }
}

.clear,
.clearIcon {
  padding: 0;
  border: none;
  background: none;
  outline: none;
}

.clearIcon {
  display: flex;
  align-items: center;
  gap: 15px;

  &:hover {
    svg {
      path {
        stroke: var(--secondary-color);
      }
    }
  }
}

.iconButton {
  width: 1.3em;
  height: 1.3em;

  svg {
    width: 100%;
    height: 100%;
  }
}

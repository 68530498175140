:root {
  // sizes
  --font: "Noto Sans", sans-serif;
  --font-secondary: "Mulish", Serif;
  --navbar-height: 80px;
  --font-size-primary: 18px;
  --font-size-secondary: 16px;

  --xs: px;
  --sm: 767px;
  --md: 1024px;
  --lg: 1360px;
  --xl: 1580px;

  // из темы, не работает drawer если сувать в файлы с темой
  --primary-bg-color: #f0f1f2;
  --secondary-bg-color: #ffffff;

  --primary-color: #1e1e1e;
  --secondary-color: #7f56d9;
  --white-color: #ffffff;
  --fourth-color: #e5ddf7;
  --gray-color: #d1d1d1;
  --color-icons-passive: #ced0d4;
  --border-radius-size-primary: 4px;
  --box-shadow-value: 0px 4px 15px 0px #0000001a;
  --border-color: #ced0d4;

  --inverted-primary-color: #7f56d9;
  --inverted-secondary-color: black;
}
